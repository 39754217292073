<template>
  <div class="page">
    <Sidebar />
    <main class="main">
      <Navbar />
      <div class="self-start w-full relative h-full overflow-y-auto">
        <section class="section">
          <div class="mt-8">
            <h1 class="font-sans font-bold text-black-base text-2xl mb-8">
              My Account
            </h1>
            <SettingsTable class="w-full"/>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Layout/Navbar";
import SettingsTable from "@/components/Account/SettingsTable";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
export default {
  name: "Account",
  components: {
    SettingsTable,
    Sidebar,
    Navbar,
  },
};
</script>
<style scoped>
.page {
  @apply flex bg-grey-light h-100vh overflow-hidden;
}
.main {
  @apply self-start w-full relative h-full overflow-y-auto;
}
.section {
  @apply px-4 md:px-12 mt-5 pb-12 max-w-2xl relative left-1/2 transform -translate-x-1/2;
}
</style>