<template>
  <div>
    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Name</h4>

          <button
            type="button"
            class="btn"
            v-if="!showNameEdit"
            @click="editName"
          >
            Edit
          </button>
          <button
            type="button"
            v-if="showNameEdit"
            @click="cancelEditName"
            class="btn"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showNameEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.name }}
        </p>
        <div>
          <InputText
            v-if="showNameEdit"
            type="text"
            class="w-full mt-2"
            name="name"
            :showLabel="false"
            placeholder="e.g. Tracy Doe"
            v-model="userDetails.name"
          />
          <ButtonPrimary v-if="showNameEdit" text="Save" class="px-8 mt-4" />
        </div>
      </div>
    </div>

    <div class="row-item">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Email</h4>

          <button
            type="button"
            class="btn"
            v-if="!showMailEdit"
            @click="editMail"
          >
            Edit
          </button>
          <button
            type="button"
            v-if="showMailEdit"
            @click="cancelEditMail"
            class="btn"
          >
            Cancel
          </button>
        </div>

        <p v-if="!showMailEdit" class="font-sans text-black-lighter text-sm">
          {{ userDetails.email }}
        </p>
        <div>
          <InputText
            v-if="showMailEdit"
            type="email"
            class="w-full mt-2"
            name="email"
            :showLabel="false"
            placeholder="e.g. john@example.com"
            v-model="userDetails.email"
          />
          <ButtonPrimary v-if="showMailEdit" text="Save" class="px-8 mt-4" />
        </div>
      </div>
    </div>

    <div :class="['row-item']">
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h4 class="font-sans font-semibold text-black-base mb-1">Password</h4>

          <button
            type="button"
            class="btn"
            v-if="!showPasswordEdit"
            @click="editPassword"
          >
            Edit
          </button>
          <button
            type="button"
            v-if="showPasswordEdit"
            @click="cancelEditPassword"
            class="btn"
          >
            Cancel
          </button>
        </div>
        <p
          v-if="!showPasswordEdit"
          class="font-sans text-black-lighter text-sm"
        >
          *********
        </p>
        <div>
          <InputText
            v-if="showPasswordEdit"
            type="password"
            class="w-full mt-2"
            name="password"
            :showLabel="false"
            placeholder="**************"
            v-model="userDetails.password"
          />
          <ButtonPrimary
            v-if="showPasswordEdit"
            text="Save"
            class="px-8 mt-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import InputText from "@/components/Inputs/InputText";
import { mapGetters } from "vuex";
export default {
  name: "SettingsTable",
  data() {
    return {
      showMailEdit: false,
      showPasswordEdit: false,
      showNameEdit: false,
      userDetails: {},
    };
  },
  components: {
    InputText,
    ButtonPrimary,
  },
  methods: {
    editMail() {
      this.showMailEdit = true;
    },
    cancelEditMail() {
      this.showMailEdit = false;
    },
    editPassword() {
      this.showPasswordEdit = true;
    },
    cancelEditPassword() {
      this.showPasswordEdit = false;
    },
    editName() {
      this.showNameEdit = true;
    },
    cancelEditName() {
      this.showNameEdit = false;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  mounted() {
    this.userDetails = { ...this.user };
  },
  props: {
    props: {
      style: String,
      default: "opacity-5",
    },
    email: {
      type: String,
      default: "tracy@travelafrique.com",
    },
  },
};
</script>
<style scoped>
.row-item {
  @apply w-full border-b border-grey-base py-8;
}
.btn {
  @apply font-sans text-teal-base focus:outline-none duration-300 transition hover:underline;
}
</style>